<template>
  <div>
    <div style="display: flex; flex-direction: row; align-items: end; margin: 5px; gap: 15px;">
      <div class="form-group" style="margin-bottom: 0;">
        <label for="operario">Operario:</label>
        <select v-model="operarioseleccionado" id="operario" class="form-control">
          <option value="-1">Seleccione operario</option>
          <option value="">Todos</option>
          <option v-for="operario in operarios" :key="operario.id" :value="operario.id">{{ operario.nombre }}</option>
        </select>
      </div>
      <div class="form-group" style="margin-bottom: 0;">
        <label for="especialidades">Especialidades:</label>
        <select v-model="especialidadseleccionada" id="especialidades" class="form-control">
          <option value="-1">Seleccione especialidad</option>
          <option value="">Todas</option>
          <option v-for="especialidad in especialidades" :key="especialidad.id" :value="especialidad.id">{{ especialidad.nombre }}</option>
        </select>
      </div>
      <div>
        <button @click="obtenerPuntos()" class="btn btn-light" style="border: 1px solid grey;">Filtrar</button>
      </div>
      <div>
        <div class="row">
          <span>Nº Visitas Totales </span>
          <strong>&nbsp;{{ totalPuntos }}</strong>
        </div>
        <div class="row" style="align-items: end;">
          <img src="https://maps.google.com/mapfiles/ms/icons/purple-dot.png" title="Ubicación del servicio">
          <span style="font-size: x-large;"></span>
          <img src="https://maps.google.com/mapfiles/ms/icons/red-dot.png" title="Visitas por confirmar">
          <span style="font-size: x-large;">{{ visitas_confirmar.length }}</span>
          <img src="https://maps.google.com/mapfiles/ms/icons/yellow-dot.png" title="Visitas pendientes">
          <span style="font-size: x-large;">{{ visitas_pendientes.length }}</span>
          <img src="https://maps.google.com/mapfiles/ms/icons/green-dot.png" title="Visitas en domicilio">
          <span style="font-size: x-large;">{{ visitas_domicilio.length }}</span>
          <img src="https://maps.google.com/mapfiles/ms/icons/blue-dot.png" title="Visitas finalizadas">
          <span style="font-size: x-large;">{{ visitas_finalizadas.length }}</span>
        </div>
      </div>
    </div>
    <div id="map" style="height: 500px;"></div>
  </div>
</template>

<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

export default {
  props:['id'],
  data() {
    return {
      totalPuntos:0,
      operarioseleccionado: -1,
      especialidadseleccionada: -1,
      center: [40.48038142908172,-4.081025004330084], // Coordenadas iniciales
      mapUrl: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      asignaciones: [],
      visitas_confirmar: [],
      visitas_pendientes: [],
      visitas_domicilio: [],
      visitas_finalizadas: [],
      operarios: [],
      especialidades: [],
      coordenadas: [],
      map: null,
    };
  },
  methods: {
    async obtenerPuntos() {
      const api = new PwgsApi();
      const body = {id_servicio:this.id};
      if (this.operarioseleccionado != -1) {
        body.id_operario = this.operarioseleccionado;
      }
      if (this.especialidadseleccionada != -1) {
        body.id_especialidad = this.especialidadseleccionada;
      }
      const puntos = await api.post('planning-pwgs/geografico', body);
      // Asignar los puntos de las diferentes categorías
      this.coordenadas = puntos.coordenadas;
      this.asignaciones = puntos.asignaciones || [];
      this.visitas_confirmar = puntos.visitas_confirmar || [];
      this.visitas_pendientes = puntos.visitas_pendientes || [];
      this.visitas_domicilio = puntos.visitas_domicilio || [];
      this.visitas_finalizadas = puntos.visitas_finalizadas || [];
      this.operariosapp = puntos.gps_operarios_app || [];
      this.operariovehiculo = puntos.gps_operarios_vehiculo || [];
      this.actualizarMapa();
      this.totalPuntos = this.asignaciones.length + this.visitas_domicilio.length+ this.visitas_finalizadas.length + this.visitas_pendientes.length + this.visitas_confirmar.length;
    },

    // Convertir las coordenadas del formato "lat,lng" a un array [lat, lng]
    convertirCoordenadas(coordenadas) {
      if (!coordenadas) return [0, 0]; // Valor por defecto si no hay coordenadas
      return coordenadas.split(',').map(Number);
    },

    // Crear un icono de marcador según el color
    crearIcono(color) {
      return L.icon({
        iconUrl: `https://maps.google.com/mapfiles/ms/icons/${color}-dot.png`, // Asegúrate de que la ruta a los iconos es correcta
        iconSize: [38, 38], // tamaño del icono
        iconAnchor: [19, 38], // punto del icono que corresponderá a la ubicación del marcador
        popupAnchor: [0, -38] // punto desde el que se abrirá el popup
      });
    },

    crearIconoUrl(url) {
      return L.icon({
        iconUrl: url, // Asegúrate de que la ruta a los iconos es correcta
        iconSize: [38, 38], // tamaño del icono
        iconAnchor: [19, 38], // punto del icono que corresponderá a la ubicación del marcador
        popupAnchor: [0, -38] // punto desde el que se abrirá el popup
      });
    },

    async obtenerOperarios() {
      const api = new PwgsApi();
      const response = await api.get('operarios/simple?sortField=nombre&sortOrder=1');
      this.operarios = response.datos;
    },

    async obtenerEspecialidades() {
      const api = new PwgsApi();
      const response = await api.get('especialidades');
      this.especialidades = response.datos;
    },

    actualizarMapa() {
      if (this.map) {
          this.map.remove(); // Eliminar el mapa existente
      }
      console.log('cordsd', this.coordenadas);
      // Inicializa el mapa
      this.map = L.map('map').setView(this.coordenadas.split(','), 13);

      L.tileLayer(this.mapUrl, {
          attribution: this.attribution
      }).addTo(this.map);

      // Agregar nuevos marcadores
      this.agregarMarcadores(this.map, this.visitas_confirmar, 'red');
      this.agregarMarcadores(this.map, this.visitas_pendientes, 'yellow');
      this.agregarMarcadores(this.map, this.visitas_domicilio, 'green');
      this.agregarMarcadores(this.map, this.visitas_finalizadas, 'blue');
      this.agregarMarcador(this.map, this.coordenadas, 'purple');
      this.agregarMarcadoresopers(this.map, this.operariosapp, require('@/assets/img/persona.png'));
      this.agregarMarcadoresopersvehi(this.map, this.operariovehiculo, require('@/assets/img/coche.png'));
    },

    agregarMarcadoresopersvehi(map, puntos, url) {
      puntos.forEach(punto => {
        const lat = punto.latitud_gps;
        const lng = punto.longitud_gps;
        
        // Crear el contenido del popup dinámicamente
        let popupContent = `          
          <div style="width:300px">            
            <div class="row" style="align-items:center">
              <i class="fas fa-user-cog"></i>
              <p>&nbsp;${punto.operario_nombre}</p>`;

        // Condicionales en JavaScript en lugar de v-if
        if (punto.operario_telefono1 !== '0') {
          popupContent += `<p>&nbsp;- ${punto.operario_telefono1}</p>`;
        }

        if (punto.operario_telefono2 !== '0') {
          popupContent += `<p>&nbsp;- ${punto.operario_telefono2}</p>`;
        }       

        const marcador = L.marker([lat, lng], { icon: this.crearIconoUrl(url) })
          .addTo(map)
          .bindTooltip(`Ubicación vehículo del operario`, { permanent: false, direction: 'top' })
          .bindPopup(popupContent);

        marcador.on('click', () => {
          marcador.openPopup();
        });
      });
    },

    agregarMarcadoresopers(map, puntos, url) {
      puntos.forEach(punto => {
        const lat = punto.latitud_gps;
        const lng = punto.longitud_gps;
        
        // Crear el contenido del popup dinámicamente
        let popupContent = `          
          <div style="width:300px">            
            <div class="row" style="align-items:center">
              <i class="fas fa-user-cog"></i>
              <p>&nbsp;${punto.operario_nombre}</p>`;

        // Condicionales en JavaScript en lugar de v-if
        if (punto.operario_telefono1 !== '0') {
          popupContent += `<p>&nbsp;- ${punto.operario_telefono1}</p>`;
        }

        if (punto.operario_telefono2 !== '0') {
          popupContent += `<p>&nbsp;- ${punto.operario_telefono2}</p>`;
        }       

        const marcador = L.marker([lat, lng], { icon: this.crearIconoUrl(url) })
          .addTo(map)
          .bindTooltip(`Ubicación del operario`, { permanent: false, direction: 'top' })
          .bindPopup(popupContent);

        marcador.on('click', () => {
          marcador.openPopup();
        });
      });
    },

    agregarMarcador(map, puntos, color) {      
        const [lat, lng] = this.convertirCoordenadas(puntos);
        L.marker([lat, lng], { icon: this.crearIcono(color) }).addTo(map).bindTooltip(`Ubicación del servicio`, { permanent: false, direction: 'top' }); 
    },

    agregarMarcadores(map, puntos, color) {
      puntos.forEach(punto => {
        const [lat, lng] = this.convertirCoordenadas(punto.coordenadas_servicio);

        // Crear el contenido del popup dinámicamente
        let popupContent = `          
          <div style="width:300px">
            <div class="row">
              <strong>${punto.codigo}</strong>
              <strong>&nbsp;${punto.daños}</strong>
            </div>
            <div class="row" style="align-items:center">
              <i class="fas fa-user-cog"></i>
              <p>&nbsp;${punto.operario_nombre}</p>`;

        // Condicionales en JavaScript en lugar de v-if
        if (punto.operario_telefono1 !== '0') {
          popupContent += `<p>&nbsp;- ${punto.operario_telefono1}</p>`;
        }

        if (punto.operario_telefono2 !== '0') {
          popupContent += `<p>&nbsp;- ${punto.operario_telefono2}</p>`;
        }

        popupContent += `
            </div>            
            <div class="row">
              <p>${punto.provincia}</p>
              <p>&nbsp;- ${punto.poblacion}</p>
              <p>&nbsp;- ${punto.direccion}</p>
              <p>&nbsp;- ${punto.codigo_postal}</p>
            </div>
            <div class="row" style="align-items:center">
              <i class="fas fa-house-user"></i>
              <p>&nbsp;${punto.nombre}</p>`;
              if (punto.telefono1 !== '') {
                popupContent += `<p>&nbsp;- ${punto.telefono1}</p>`;
              }

              if (punto.telefono2 !== '') {
                popupContent += `<p>&nbsp;- ${punto.telefono2}</p>`;
              }
              if (punto.telefono3 !== '') {
                popupContent += `<p>&nbsp;- ${punto.telefono3}</p>`;
              }
        popupContent += `
            </div>
            <div class="row">
              <p>${punto.fecha}</p>
              <p>&nbsp;- ${punto.hora}</p>
            </div>
          </div>
        `;

        const marcador = L.marker([lat, lng], { icon: this.crearIcono(color) })
          .addTo(map)
          .bindTooltip(`${punto.operario_nombre}`, { permanent: false, direction: 'top' })
          .bindPopup(popupContent);

        marcador.on('click', () => {
          marcador.openPopup();
        });
      });
    }
  },
  created() {
    this.obtenerOperarios();
    this.obtenerEspecialidades();
    this.obtenerPuntos();
  }
};
</script>

<style>
#map { height: 500px; }
</style>
